<template>
    <div id="NoticeDetail">
        <div class="container">
            <div class="board_tit_box underline mb-4">
                <p class="tit">{{info.title}}</p>
                <p class="date">{{info.date}}</p>
            </div>
            <pre class="board_content_box">
                {{info.memo}}
            </pre>
        </div>
    </div>
</template>

<script>


export default {
    data(){
        return{
            title: this.$t("menu.1"),
            prevP: false,
            pageD: false,
            index: this.$route.params.index,
            info: {}
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: {
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){    
                        this.noticeDetail();
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        noticeDetail: function() {
            const api = this.$store.state.baseURL;
            const no = this.index;
            
            this.$http.post(`${api}/member/board/notice/detail`,{no}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.info = res.data.info;
                        }
                    }
                }
            )
        }
    },
}
</script>
